import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export default function BillingRouter() {
    const DatatableCompanyPending = lazy(() => import('../../pages/business/companies/DatatableCompanyPending'))
    const DatatableCompany = lazy(() => import('../../pages/business/companies/DatatableCompany'))
    const ProfileCompany = lazy(() => import('../../pages/business/companies/ProfileCompany'))
    const RegisterCompany = lazy(() => import('../../pages/business/companies/RegisterCompany'))

    const ShowOrder = lazy(() => import('../../pages/business/orders/ShowOrder'))
    const DatatablePaymentApproved = lazy(() => import('../../pages/business/orders/DatatableOrders'))
    const DatatablePaymentPending = lazy(() => import('../../pages/business/orders/DatatablePaymentPending'))

    const DatatableUsers = lazy(() => import('../../pages/business/users/DatatableUsers'))
    const ShowUser = lazy(() => import('../../pages/business/users/ShowUser'))
    
    return (
        <Switch>
            <Route path='/empresarial/empresas/cadastro' component={RegisterCompany} />
            <Route path='/empresarial/empresas/pendentes' component={DatatableCompanyPending} />
            <Route path='/empresarial/empresas/listagem' component={DatatableCompany} />
            <Route path='/empresarial/empresas/:id' component={ProfileCompany} />

            <Route path='/empresarial/pedidos/pendentes' component={DatatablePaymentPending} />
            <Route path='/empresarial/pedidos/listagem' component={DatatablePaymentApproved} />
            <Route path='/empresarial/pedidos/:id' component={ShowOrder} /> 

            <Route path='/empresarial/usuarios/:cpf' component={ShowUser} /> 
            <Route path='/empresarial/usuarios' component={DatatableUsers} /> 
        </Switch>
    )
}
