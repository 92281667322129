import {Field, FieldProps} from 'formik'
import React, {useState} from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    required?: boolean
}

export default function ImageInput({name, label, required}: Props) {
    const [preview, setPreview] = useState<string | ArrayBuffer | null>('/media/logos/avatar.jpg')

    return (
        <Field name={name}>
            {({form}: FieldProps) => {
                const photoUpload = (e: any) => {
                    e.preventDefault()
                    const reader = new FileReader()
                    const file = e.target.files[0]
                    if (reader !== undefined && file !== undefined) {
                        reader.onloadend = (readerEvt: any) => {
                            setPreview(reader.result)
                            form.setFieldValue(name, reader.result)
                        }
                        reader.readAsDataURL(file)
                    }
                }
                const onDelete = () => {
                    setPreview('/media/logos/avatar.jpg')
                    form.setFieldValue(name, null)
                }
                return (
                    <>
                        <label
                            htmlFor={name}
                            className='form-label fs-6 fw-bolder d-flex flex-column p-0 m-0 position-relative'
                        >
                            {label}
                            {required && <span className='text-danger'>*</span>}
                            <div className='h-200px d-flex justify-content-center align-items-center'>
                                <img
                                    className='rounded-2 border-2 rounded border-light-dark border mw-150px'
                                    // style={{width: '100%'}}
                                    src={String(preview)}
                                    alt={name}
                                />
                            </div>
                            <label
                                htmlFor={name}
                                className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute bottom-0 m-4 border border-1 border-gray'
                            >
                                <i className='fas fa-camera p-0' />
                            </label>
                            <button
                                type='button'
                                className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute end-0 bottom-0 m-4 border border-1 border-gray'
                                onClick={onDelete}
                            >
                                <i className='fas fa-trash p-0' />
                            </button>
                        </label>
                        <input
                            type='file'
                            accept='image/jpeg, image/png, image/jpg'
                            name={name}
                            id={name}
                            onChange={photoUpload}
                            className='d-none'
                        />
                    </>
                )
            }}
        </Field>
    )
}
