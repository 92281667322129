import {toast} from 'react-toastify'

export default function setupAxios(axios: any, store: any) {
    axios.interceptors.request.use(
        (config: any) => {
            config.baseURL = process.env.REACT_APP_API_URL
            const {
                auth: {token},
            } = store.getState()
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }

            return config
        },
        (err: any) => {
            return Promise.reject(err)
        }
    )
    axios.interceptors.response.use(
        (config: any) => config,
        async (err: any) => {
            if (err.response?.data?.message) {
                toast.error(err.response.data.message)
            } else {
                toast.error('Falha ao realizar comunicação, tente novamente.')
            } 

            if(err.response?.status === 403 && window.location.pathname !== '/logout') 
            {
                window.location.replace('/logout')
                toast.error(`${err.response?.status} - ${err.response?.statusText}`)
            }
            return Promise.reject(err)
        }
    )
}
