import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {User} from '../models/UserModel'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    Register: '[Register] Action',
    UserRequested: '[Request User] Action',
    UserLoaded: '[Load User] Auth API',
    SetUser: '[Set User] Action',
    refresh_token: '[Refresh Token] Action',
}

const initialAuthState: IAuthState = {
    user: undefined,
    token: undefined,
    refresh_token: undefined,
}

export interface IAuthState {
    user?: User
    token?: string
    refresh_token?: string
}

export const reducer = persistReducer(
    {storage, key: 'v100-demo1-auth', whitelist: ['user', 'token', 'refresh_token']},
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
        switch (action.type) {
            case actionTypes.Login: {
                const token = action.payload?.token
                const refresh_token = action.payload?.refresh_token
                return {token, user: undefined, refresh_token}
            }

            case actionTypes.Register: {
                const token = action.payload?.token
                const refresh_token = action.payload?.refresh_token

                return {token, user: undefined, refresh_token}
            }

            case actionTypes.Logout: {
                return initialAuthState
            }

            case actionTypes.UserRequested: {
                return {...state, user: undefined}
            }

            case actionTypes.UserLoaded: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.SetUser: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.refresh_token: {
                const token = action.payload?.token
                const refresh_token = action.payload?.refresh_token
                return {
                    token,
                    refresh_token,
                    user: undefined,
                }
            }

            default:
                return state
        }
    }
)

export const actions = {
    login: (token: string, refresh_token: string) => ({
        type: actionTypes.Login,
        payload: {token, refresh_token},
    }),
    register: (token: string) => ({
        type: actionTypes.Register,
        payload: {token},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
        type: actionTypes.UserRequested,
    }),
    fulfillUser: (user: User) => ({type: actionTypes.UserLoaded, payload: {user: user}}),
    setUser: (user?: User) => ({type: actionTypes.SetUser, payload: {user: user}}),
    refresh_token: (token: string, refresh_token: string) => ({
        type: actionTypes.refresh_token,
        payload: {token, refresh_token},
    }),
}

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data} = yield getUserByToken()
        yield put(actions.fulfillUser(data.data.user))
    })
}
