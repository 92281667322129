import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import { Tooltip } from '../../../app/modules/components'
import {
    ScrollTopComponent,
    MenuComponent,
    DrawerComponent,
    ToggleComponent,
    StickyComponent,
} from '../../assets/ts/components'

export function ScrollTop() {
    const {pathname} = useLocation()
    const isFirstRun = useRef(true)

    const pluginsReinitialization = () => {
        setTimeout(() => {
            MenuComponent.reinitialization()
            StickyComponent.reInitialization()
            setTimeout(() => {
                ToggleComponent.reinitialization()
                DrawerComponent.reinitialization()
            }, 70)
        }, 140)
    }

    const scrollTop = () => {
        ScrollTopComponent.goTop()
    }

    const updateHeaderSticky = () => {
        const stickyHeader = document.body.querySelectorAll(`[data-kt-sticky-name="header"]`)
        if (stickyHeader && stickyHeader.length > 0) {
            const sticky = StickyComponent.getInstance(stickyHeader[0] as HTMLElement)
            if (sticky) {
                sticky.update()
            }
        }
    }

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
        } else {
            pluginsReinitialization()
        }

        updateHeaderSticky()
        setTimeout(() => {
            scrollTop()
        }, 0)
    }, [pathname])

    return (
        <Tooltip text="Retornar ao topo">
            <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true'>
                <i className='fas fa-arrow-up'></i>
            </div>
        </Tooltip>
        
    )
}
