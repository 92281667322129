import clsx from 'clsx'
import {FC} from 'react'
import { Tooltip } from '../../../../app/modules/components'
import {HeaderUserMenu} from '../../../partials'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                <Tooltip text="Configurações de perfil" placement="bottom">
                    <div
                        className={clsx(
                            'cursor-pointer symbol btn btn-icon btn-active-light-primary',
                            toolbarUserAvatarHeightClass
                        )}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <i className={toolbarButtonIconSizeClass + ' fas fa-user-circle'}></i>
                    </div>
                </Tooltip>
                
                <HeaderUserMenu />
            </div>
        </div>
    )
}

export {Topbar}
