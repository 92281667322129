import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../Form'
import {initialValuesLogin, loginSchema} from '../models/AuthModel'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import {HeaderTextWidget} from '../widgets/HeaderTextWidget'

export function Login() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [inputType, setInputType] = useState('password')
    const [eye, setEye] = useState('bi bi-eye-slash-fill')

    function toggleEye(e: EventTarget, eye: string) {
        if (eye === 'bi bi-eye-fill') {
            setEye('bi bi-eye-slash-fill')
            setInputType('password')
            return
        } else if (eye === 'bi bi-eye-slash-fill') {
            setEye('bi bi-eye-fill')
            setInputType('text')
            return
        }
    }

    async function handleSubmit(values: typeof initialValuesLogin) {
        try {
            setLoading(true)
            const {data} = await login(values.email, values.password)
            dispatch(auth.actions.login(data.data.token, data.data.refresh_token))
        } finally {
            setLoading(false)
        }
    }
    return (
        <Formik
            initialValues={initialValuesLogin}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
        >
            <Form className='form w-100' noValidate id='kt_login_signin_form'>
                <HeaderTextWidget>
                    Bem-vindo(a) a RubyY!
                    <br />O plano é não ter plano.
                </HeaderTextWidget>

                <div className='fv-row mb-5'>
                    <TextInput
                        name='email'
                        placeholder='Entre com o e-mail'
                        label='E-mail'
                        type='email'
                    />
                </div>

                <div className='fv-row mb-5'>
                    <TextInput
                        name='password'
                        placeholder='Entre com a senha'
                        label='Senha'
                        type={inputType}
                        eye={eye}
                        toggleEye={toggleEye}
                    />
                </div>

                <div className='text-center'>
                    <ButtonAuthSubmit loading={loading} showBottomMargin={false}></ButtonAuthSubmit>
                    <div className='my-3 fw-bold text-dark'>OU</div>
                    <ButtonAuthCancel
                        buttonTitle='Esqueceu a senha?'
                        buttonUrl={'/autenticacao/esqueceu-senha'}
                    />
                </div>
            </Form>
        </Formik>
    )
}
