import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function SupportRouter() {
    /*Rotas para Historico de Atividades*/
    const DatatableMyTicket = lazy(() => import('../../pages/support/tickets/DatatablePendingTicket'))
    const DatatableAllTicket = lazy(() => import('../../pages/support/tickets/DatatableAllTicket'))
    const ShowTicket = lazy(() => import('../../pages/support/tickets/ShowTicket'))
    const ListFaqs = lazy(() => import('../../pages/support/faqs/ListFaqs'))
    const RegisterFaq = lazy(() => import('../../pages/support/faqs/RegisterFaq'))
    const EditFaq = lazy(() => import('../../pages/support/faqs/EditFaq'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/suporte/tickets/meus' component={DatatableMyTicket} />
            <Route path='/suporte/tickets/todos' component={DatatableAllTicket} />
            <Route path='/suporte/tickets/:id' component={ShowTicket} />
            <Route path='/suporte/faqs/cadastro' component={RegisterFaq} />
            <Route path='/suporte/faqs/:id' component={EditFaq} />
            <Route path='/suporte/faqs/' component={ListFaqs} />
        </Switch>
    )
}
