interface Props {
    loading: boolean
    buttonTitle?: string
    showBottomMargin?: boolean
}
export default function ButtonSubmit({
    loading,
    buttonTitle = 'Continuar',
    showBottomMargin = true,
}: Props) {
    return (
        <button
            type='submit'
            className={`btn btn-lg btn-primary w-100 ${showBottomMargin ? 'mb-5' : ''}`}
        >
            {!loading && <span className='indicator-label '>{buttonTitle}</span>}
            {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                    Aguarde...
                </span>
            )}
        </button>
    )
}
