interface Props {
    title: string
    icon?: string
    color?: string
    margin?: string
}

export default function TagAlert({title, margin='mb-5', icon='fas fa-exclamation-triangle', color='warning'}: Props) {
    return (
        <div className={`row ${margin ?? 'mb-5'}`}>
            <div className='col-lg-12'>
                <div className={`alert alert-${color ?? 'warning'} d-flex align-items-center m-0`}>
                    <i className={`${icon ?? 'fas fa-exclamation-triangle'} fa-3x text-${color ?? 'warning'} me-2`}></i>
                    <span className='fw-bold fs-6'>{title}</span>
                </div>
            </div>
        </div>
    )
}
