import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {RequestUserByToken} from '../models/UserModel'

export const GET_USER_BY_token_URL = `/profile/show`
export const LOGIN_URL = `/auth/login`
export const LOGOUT_URL = `/auth/logout`
export const REGISTER_URL = `/auth/register`

export const REQUEST_PASSWORD_URL = `/auth/password/forgot`
export const RESET_PASSWORD_URL = `/auth/password/reset`

export const AUTH_MFA_URL = `/auth/check_mfa`
export const REFRESH_TOKEN_URL = `/auth/refresh_token`

export function login(email: string, password: string) {
    return axios.post(LOGIN_URL, {email, password})
}

export function logout() {
    return axios.post(LOGOUT_URL)
}

export function requestPassword(email: string) {
    return axios.post<{success: boolean; message: string}>(REQUEST_PASSWORD_URL, {email})
}

export function requestResetPassword(
    token: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(RESET_PASSWORD_URL, {token, email, password, password_confirmation})
}
export function getUserByToken() {
    return axios.get<RequestUserByToken>(GET_USER_BY_token_URL)
}
export function authMfa(email: string, password: string, token_mfa: string) {
    return axios.post(AUTH_MFA_URL, {email, password, token_mfa})
}
export function refresh_token(refresh_token: string) {
    return axios.post<AuthModel>(REFRESH_TOKEN_URL, {refresh_token})
}
