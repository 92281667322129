import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput} from '../../Form'
import {requestPassword} from '../redux/AuthCRUD'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import Alert from './Alert'

const initialValues = {
    email: '',
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required('E-mail é obrigatório'),
})

export function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const history = useHistory()

    async function handleSubmit(
        values: typeof initialValues,
    ) {
        try {
            setLoading(true)
            await requestPassword(values.email)
            setOpenAlert(true)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
        >
            {(props) => (
                <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate>
                    <HeaderTextWidget>
                        Esqueceu a senha ?
                        <br />
                        Entre com o e-mail para realizar a alteração da senha.
                    </HeaderTextWidget>
                    <div className='fv-row mb-10'>
                        <TextInput
                            name='email'
                            label='E-mail'
                            type='email'
                            placeholder='Entre com o e-mail'
                        />
                    </div>
                    <div className='text-center'>
                        <ButtonAuthSubmit loading={loading}></ButtonAuthSubmit>
                        <ButtonAuthCancel buttonUrl={'/autenticacao/entrar'}></ButtonAuthCancel>
                    </div>
                    <Alert
                        onClose={() => {
                            setOpenAlert(false)
                            history.push('/')
                        }}
                        title='As instruções para alterar sua senha foram enviadas para seu e-mail, por favor, verifique-o!'
                        open={openAlert}
                    />
                </Form>
            )}
        </Formik>
    )
}
