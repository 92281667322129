import {Link} from 'react-router-dom'

interface Props {
    title: string
    url?: string
    onClick?: () => void
    icon?: string
    color?: string
    margin?: string
}
export function ButtonNew({title, url, icon, onClick, color = 'success', margin = ''}: Props) {
    return url ? (
        <Link to={url} className={`btn btn-sm btn-light-${color} ${margin}`}>
            <i className={icon ?? 'fa fa-plus-circle'}></i>
            {title}
        </Link>
    ) : (
        <button onClick={onClick} className={`btn btn-sm btn-light-${color} ${margin}`}>
            <i className={icon ?? 'fa fa-plus-circle'}></i>
            {title}
        </button>
    )
}
