import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ReportsRouter() {
    /*Rotas para Dashboard*/
    const DashboardReportFinancial = lazy(() => import('../../pages/reports/DatatableReportFinancial'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/relatorios/financeiro' component={DashboardReportFinancial} />
        </Switch>
    )
}
